export const useGeneralAgree = () => useState('agree', () => false)

export const useModalsDiary = () => useState('useModalsDiary', () => null)
export const useModalsDiaryShow = () => useState('useModalsDiaryShow', () => false)
export const useModalsDiaryModalRef = () => useState('useModalsDiaryModalRef', () => false)

// export const useRequest = (url:string, options:object) => {
//   return $fetch(url, options).then((res) => {
//     console.log(res);
//     return res;
//   }).catch((err) => {
//     return err;
//   })
// }